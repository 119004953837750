.c-musical-image {
  position: relative;
  margin: 15px 0;
}

.c-musical-image__image {
  display: block;
}

.c-musical-image__audio-link {
  min-height: 20px;
  margin: 10px 0;

  line-height: 20px;
  text-align: center;
}

.c-musical-image__audio-link a {
  color: inherit;
  text-decoration: underline;
}
