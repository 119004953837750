:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-footer-nav {
  position: relative;
  background: linear-gradient(90deg, #221e35, #221e35);
}

.c-footer-nav__list {
  position: relative;
  display: flex;
  height: 48px;
  max-width: 600px;
  padding: 0;
  margin: 0 auto;
  list-style: none;
}

.c-footer-nav__item {
  flex: 1;
  height: 100%;
  min-width: 0;
  text-transform: uppercase;
  font-size: 15px;
}

.c-footer-nav__link {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 7px 5px;
  border: 0;
  background: transparent;
  color: white;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
}

.c-footer-nav__link svg {
  opacity: 0.5;
}

.c-footer-nav__link:hover svg,
.c-footer-nav__link--active svg {
  opacity: 1;
}

.c-footer-nav__link--active::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 4px;
  width: 48px;
  margin: auto;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(90deg, #fff 0%, #e0321c 100%);
}

.c-footer-nav__name {
  font-size: 6px;
  font-family: sans-serif;
  font-weight: normal;
  margin-top: 4px;
}

.c-footer-nav__notif {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: calc(50% + 3px);
  top: 6px;
}