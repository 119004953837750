:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-badges {
  width: 100%;
  max-width: 480px;
  margin: 20px auto;
}

.c-badges__title {
  margin: 20px 20px 0;
  border-bottom: 1px rgba(255, 255, 255, 0.25) solid;
  color: #b8e986;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
}

.c-badges__list {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 10px 0;
}

.c-badges__badge {
  position: relative;
  padding: 0;
  background: transparent;
  border: none;
  font: inherit;
  color: white;
  cursor: pointer;
}

.c-badges__badge--new::before {
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto auto;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px white;
  opacity: 0.3;
}

.c-badges__notif {
  background: rgba(255, 255, 255, 0.2);
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 6px;
  padding: 5px 10px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.c-badges__notif_icon {
  margin-right: 5px;
  flex: none;
}