:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-rules {
  padding: 20px;
  max-width: 480px;
  margin: 0 auto;
}

.c-rules__title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

.c-rules__chapter {
  display: flex;
  align-items: baseline;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  margin: 30px 0 0;
}

.c-rules__chapter::before,
.c-rules__chapter::after {
  content: "";
  display: block;
  flex: auto;
  height: 0;
  border-bottom: 1px white solid;
}

.c-rules__chapter::before {
  margin-right: 1em;
}

.c-rules__chapter::after {
  margin-left: 1em;
}