:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-autologin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background: linear-gradient(135deg, #221e35, #221e35);
  color: white;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.c-autologin__header {
  margin-top: 25px;
}

.c-autologin__header-title {
  font-size: 32px;
}

.c-autologin__header-subtitle {
  font-size: 26px;
}

.c-autologin__logo {
  width: 52px;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.c-autologin__text {
  text-align: left;
  font-size: 14px;
}

.c-autologin__text-header {
  text-align: center;
  margin-bottom: 25px;
}

.c-autologin__notice {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 220px;
  margin: 30px auto;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.c-autologin__about {
  margin-top: auto;
  color: #e0321c;
  text-decoration: underline;
  font-size: 10px;
  font-family: sans-serif;
  font-weight: normal;
}