:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-story-preview {
  display: block;
  width: 100%;
  padding: 5px 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.c-story-preview--teaser {
  cursor: default;
}

.c-story-preview:not(.c-story-preview--completed) {
  background: white;
}

.c-story-preview--display {
  cursor: default;
}

.c-story-preview--only,
.c-story-preview--first {
  padding-top: 15px;
}

.c-story-preview--only,
.c-story-preview--last {
  padding-bottom: 15px;
}

.c-story-preview__line {
  display: flex;
  justify-content: space-between;
  line-height: 19px;
  font-size: 12px;
  align-items: center;
}

.c-story-preview__title {
  line-height: 19px;
  font-weight: bold;
  font-size: 14px;
  color: #3e2623;
}

.c-story-preview--completed .c-story-preview__title {
  opacity: 0.5;
}

.c-story-preview__star {
  width: 12px;
  margin: 0 2px;
}

.c-story-preview__xp {
  font-weight: bold;
  font-size: 12px;
}

.c-story-preview--completed .c-story-preview__xp {
  color: #e0321c;
}

.c-story-preview__zone {
  color: #9b9b9b;
  margin-right: 3px;
}

.c-story-preview__stats {
  display: flex;
  justify-content: space-between;
  width: 95px;
}

.c-story-preview--completed .c-story-preview__stats {
  color: #e0321c;
}

.c-story-preview__highlight {
  color: #e0321c;
}

.c-story-preview__level {
  margin-left: 3px;
}