:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-selectable-grid {
  margin: 15px 0;
}

.c-selectable-grid__success {
  border: 1px solid black;
  padding: 20px;
  text-align: center;
}

.c-selectable-grid__table {
  width: 100%;
  color: #3e2623;
  border-collapse: collapse;
  border: 1px solid black;
  table-layout: fixed;
  user-select: none;
}

.c-selectable-grid__cell {
  border: none;
  font-weight: bold;
  text-align: center;
  padding: 0;
}

.c-selectable-grid__cell--empty {
  background: lightgray;
}

.c-selectable-grid__cell--validated {
  background: #2ecc40;
}

.c-selectable-grid__select {
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  font: inherit;
  text-align: center;
}