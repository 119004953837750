.c-witch-answer {
  height: 100vh;
  height: 100svh;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 200px;
  background-image: url('/images/sorciere_answer.png');
  background-repeat: no-repeat;
  background-size: auto 200px;
  background-position: bottom left;
}

.c-witch-answer__content {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}

.c-witch-answer__label {
  position: relative;
  width: 80%;
  background: white;
  padding: 20px;
  margin: 0;
  border-radius: 20px;
}

.c-witch-answer__label::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 46px;
  border: 10px solid white;
  border-color: white transparent transparent;
}

.c-witch-answer__player {
  position: relative;
  margin-left: 20%;
  margin-bottom: 20px;
  width: 80%;
}

.c-witch-answer__player::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  border: 10px solid white;
  border-color: transparent transparent transparent white;
}

.c-witch-answer__textarea {
  background: white;
  padding: 20px;
  border-radius: 20px;
  text-transform: uppercase;
}

.c-witch-answer__action {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  right: 0;
}

.c-witch-answer__close {
  position: fixed;
  top: 15px;
  right: 15px;

  margin: 0;
  padding: 0;
  border: none;
  background: none;

  cursor: pointer;
}

.c-default__action {
  margin: 20px 0;
  text-align: center;
}

.c-default__label {
  margin: 20px 0;
  text-align: center;
}

.u-reset-textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  font: inherit;
  color: inherit;
  resize: none;
}
