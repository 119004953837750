.o-stamp {
  background-image: url('/images/stamp-check.svg');
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: top 15px right -18px;
}

.o-stamp--blocked {
  background-image: url('/images/stamp-cross.svg');
}
