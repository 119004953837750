.c-spot-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.c-spot-list__title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
}

.c-spot-list__list {
  flex: auto;
  min-height: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.c-spot-list__list > p {
  text-align: center;
}

.c-spot-list__item {
  padding: 10px;
}
