@import '~styles/variables/fonts.scss';

@font-face {
  font-family: 'Miriam';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MiriamLibre.woff') format('woff');
}

@font-face {
  font-family: 'Miriam';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/MiriamLibreBold.woff') format('woff');
}

@font-face {
  font-family: 'RoughTypewriter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/RoughTypewriter.woff') format('woff');
}

@font-face {
  font-family: 'PressStart2P';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/PressStart2P.woff') format('woff');
}

@font-face {
  font-family: 'Stormblade';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Stormblade.woff') format('woff');
}

html {
  font-family: $mainFont;
}

option {
  /* Avoid bug in MacOS */
  font-family: Arial, sans-serif;
}
