:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-unpixelate-image__label {
  display: block;
  margin: 14px 0;
  color: #888888;
}

.c-unpixelate-image__message {
  display: block;
  margin: 14px 0;
  color: #b40002;
}

.c-unpixelate-image__message--error {
  color: #b40002;
}

.c-unpixelate-image__message--validation {
  color: #02b400;
}

.c-unpixelate-image__input-group {
  display: flex;
}

.c-unpixelate-image__input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 0.5em;
  margin: 0 10px 0 0;
  border: 1px solid #221e35;
  line-height: 24px;
}

.c-unpixelate-image__input:focus {
  outline: none;
}

.c-unpixelate-image__image {
  position: relative;
  margin: 14px 0;
}

.c-unpixelate-image__image--large {
  margin: 14px -20px;
}

.c-unpixelate-image__canvas {
  display: block;
  width: 100%;
}

.c-unpixelate-image__counter {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-family: monospace, monospace;
  font-weight: bold;
  text-shadow: 1px 1px white;
}

.c-unpixelate-image__counter--bottom {
  top: auto;
  bottom: 10px;
}

.c-unpixelate-image__validated {
  padding: 0;
  list-style: none;
}