.c-time-machine {
  position: relative;
}

.c-time-machine__canvas {
  display: block;
  width: 100%;
}

.c-time-machine__switch-1 {
  position: absolute;
  top: 59.59%; /* 786 /1319 */
  left: 10%; /* 98 / 977 */
  width: 10.35%;
  height: 10.61%;
}
