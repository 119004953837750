:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-password-protection__label {
  display: block;
  margin: 14px 0;
  color: #888888;
}

.c-password-protection__message {
  display: block;
  margin: 14px 0;
  color: #b40002;
}

.c-password-protection__input-group {
  display: flex;
}

.c-password-protection__input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 0.5em;
  margin: 0;
  border: 1px solid #221e35;
  line-height: 24px;
}

.c-password-protection__input:focus {
  outline: none;
}