@import '~styles/variables/colors.scss';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: $color-main;
}

body {
  overscroll-behavior: contain;
}

html {
  line-height: 1.5;
  scroll-behavior: smooth;
}

figure {
  display: block;
  margin: 30px 0;
}

img {
  max-width: 100%;
}

a {
  color: white;
  text-decoration: none;
}
