:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

@keyframes player-marker__pulse {
  0% {
    transform: scale(0.4);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.c-player-marker__content {
  padding: 10px;
  text-align: center;
}

.c-player-marker__popup .leaflet-popup-close-button {
  display: none;
}

.c-player-marker__popup .leaflet-popup-content-wrapper {
  box-shadow: none;
  border-radius: 12px;
}

.c-player-marker__icon::before {
  content: "";
  display: block;
  position: absolute;
  top: -6px;
  left: -6px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: rgba(34, 30, 53, 0.8);
  box-sizing: content-box;
  background-clip: content-box;
  animation: player-marker__pulse 1.5s infinite;
}

.c-player-marker__icon--orientation:before {
  display: none;
}

.c-player-marker__orientation {
  position: absolute;
  left: calc(50% - 7px);
  bottom: calc(50% - 13px);
  height: 30px;
  width: 30px;
  border-radius: 0 100% 0 12px;
  background: radial-gradient(circle at top right, rgba(34, 30, 53, 0) 45%, rgba(34, 30, 53, 0.9));
  transform-origin: 7px 18px;
}

.c-player-marker__icon::after {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #221e35;
  box-sizing: content-box;
  background-clip: content-box;
  z-index: 1000;
}