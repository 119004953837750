@import '~styles/variables.scss';
@import '~styles/mixins.scss';

.o-button,
.o-button:any-link {
  display: inline-block;
  width: 100%;
  padding: 10px 24px;

  @include background__button();
  border: none;
  border-radius: $border-radius__button;

  font: inherit;
  font-weight: bold;
  font-size: 14px;
  color: white;
  text-align: center;
  text-decoration: none;
  line-height: 22px;

  cursor: pointer;
  outline: none;
}

.o-button--fit,
.o-button--fit:any-link {
  width: auto;
}

.o-button--tiny,
.o-button--tiny:any-link {
  width: auto;
  height: auto;
  padding: 6px 12px;
  line-height: 1;
}

.o-button--alternate,
.o-button--alternate:any-link {
  @include background__button--alternate();
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.5);
}

.o-button--no-shadow,
.o-button--no-shadow:any-link {
  box-shadow: none;
}

.o-button--bordered,
.o-button--bordered:any-link {
  border: $color-main solid 2px;
  background: white;
  color: $color-main;
}

.o-button--alert,
.o-button--alert:any-link {
  background: $color-alert;
}

.o-button svg {
  display: block;
  width: 30px;
  height: 30px;
}
