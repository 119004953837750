:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-color-name {
  display: table;
  margin: 20px auto 0;
}

.c-color-name__action {
  display: flex;
}

.c-color-name__select {
  background: transparent;
  border: 1px solid black;
  border-left-color: #ccc;
  margin-left: -1px;
  padding: 5px;
  text-align: center;
}

.c-color-name__select:first-child {
  background: #ccc;
  border-left-color: black;
  border-radius: 5px 0 0 5px;
}

.c-color-name__select:last-child {
  border-radius: 0 5px 5px 0;
}

.c-color-name__output {
  margin-top: 10px;
  padding: 5px 20px;
  color: white;
  text-align: center;
}

.c-color-name__output--light {
  color: black;
}