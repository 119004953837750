:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-musical-svg {
  color: #221e35;
}

.c-musical-svg path {
  fill: #221e35;
}

.c-musical-svg .u-stroke {
  fill: none;
  stroke: #221e35;
}

path.c-musical-svg__clicked {
  fill: #fff;
}