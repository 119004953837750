:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-chat {
  border: 1px solid gray;
  padding: 5px 20px;
  border-radius: 20px;
}

.c-chat__action-list {
  display: block;
  padding: 0;
  margin: 30px 0;
  list-style: none;
}

.c-chat__action {
  display: block;
  max-width: 280px;
  margin: 15px auto 0;
}