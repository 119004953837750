:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-welcome {
  padding: 0 15px 15px 15px;
  min-height: 100%;
  text-align: center;
  color: white;
  background: linear-gradient(to top left, #221e35 0%, #221e35 100%);
}

.c-welcome__header {
  padding-top: 25px;
  max-width: 500px;
  margin: auto;
}

.c-welcome__logo {
  width: 52px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.c-welcome__header-text {
  width: 90px;
}

.c-welcome__text {
  text-align: left;
  margin-top: 40px;
  font-size: 14px;
}

.c-welcome__link {
  text-decoration: underline;
  font-weight: bold;
}

.c-welcome__text-header {
  text-align: center;
}