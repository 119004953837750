:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-cross-words-grid {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #3e2623;
}

.c-cross-words-grid__row {
  position: relative;
  display: flex;
  height: 40px;
  margin-top: -1px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.c-cross-words-grid__input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  outline: none;
  border: 0;
  background: none;
  opacity: 0;
  font: inherit;
  text-transform: inherit;
  letter-spacing: 16px;
}

.c-cross-words-grid__cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  padding: 0;
  margin-right: -1px;
  border: 1px solid #3e2623;
}

.c-cross-words-grid__cell--hidden {
  border-color: transparent;
  pointer-events: none;
}

.c-cross-words-grid__cell--legend {
  position: relative;
  white-space: nowrap;
  text-transform: none;
  font-weight: normal;
}

.c-cross-words-grid__cell.c-cross-words-grid__cell--legend > span {
  position: absolute;
  left: 5px;
}

.c-cross-words-grid__cell--highLight {
  background: #b8e986;
}

.c-cross-words-grid__number {
  font-size: 10px;
  font-family: sans-serif;
  position: absolute;
  bottom: 0;
  right: 3px;
}