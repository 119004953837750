:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-status-bar {
  display: block;
  position: relative;
  padding: 10px 40px 10px 10px;
  color: #e0321c;
  text-align: center;
  border-bottom: 1px solid #e6e6f0;
  background-color: white;
}

.c-status-bar:empty {
  display: none;
}

.c-status-bar__icon {
  vertical-align: middle;
  margin-right: 0.5em;
}

.c-status-bar__label {
  vertical-align: middle;
}

.c-status-bar__button {
  margin: 5px;
}

.c-status-bar__cross {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
}

.c-status-bar__cross svg {
  display: block;
}

.c-status-bar__link {
  color: inherit;
}