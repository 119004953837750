:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-hidden-words-grid {
  margin: 20px auto;
  width: 100%;
  color: #3e2623;
  border-collapse: collapse;
  table-layout: fixed;
  user-select: none;
  touch-action: none;
}

.c-hidden-words-grid__cell {
  border: #3e2623 solid 1px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
}

.c-hidden-words-grid__letter {
  display: block;
  width: 1em;
  height: 1em;
  margin: auto;
  line-height: 1;
}

.c-hidden-words-grid__cell--hidden,
.c-hidden-words-grid__cell--hidden-always {
  background-color: #3e2623;
}

.c-hidden-words-grid__cell--hidden-always {
  pointer-events: none;
}