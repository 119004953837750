:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-subscribe {
  padding: 0 15px 15px 15px;
  min-height: 100%;
  text-align: center;
  color: white;
  background: linear-gradient(to top left, #221e35 0%, #221e35 100%);
}

.c-subscribe__content {
  max-width: 700px;
  margin: auto;
}

.c-subscribe__header {
  padding-top: 25px;
}

.c-subscribe__chapter {
  display: flex;
  align-items: baseline;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0 20px;
}

.c-subscribe__chapter::before,
.c-subscribe__chapter::after {
  content: "";
  display: block;
  flex: auto;
  height: 0;
  border-bottom: 1px white solid;
}

.c-subscribe__chapter::before {
  margin-right: 1em;
}

.c-subscribe__chapter::after {
  margin-left: 1em;
}

.c-subscribe__logo {
  width: 52px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.c-subscribe__header-text {
  width: 90px;
}

.c-subscribe__form {
  max-width: 480px;
  margin: 15px auto 0;
}

.c-subscribe__form input {
  width: 100%;
  border-radius: 22px;
  border: none;
  height: 40px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  outline: none;
  color: #3e2623;
}

.c-subscribe__form input {
  margin-bottom: 5px;
}

.c-subscribe__form input::placeholder {
  color: #9b9b9b;
}

.c-subscribe__error {
  color: #e0321c;
  font-size: 12px;
  text-align: center;
}

.c-subscribe__form input.c-subscribe__input-error {
  border: #e0321c solid 1px;
}

.c-subscribe__input-label {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
}

.c-subscribe__input-legend {
  font-size: 10px;
  font-family: sans-serif;
  text-align: left;
  margin-left: 18px;
  color: #fff;
}

.c-subscribe__button {
  margin-top: 20px;
  display: block;
}

.c-subscribe__button-disabled {
  background: #cccccc;
}

.c-subscribe__intro {
  max-width: 500px;
  margin: 20px auto;
  text-align: left;
  font-size: 14px;
}

.c-subscribe__products {
  flex: auto;
  min-height: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.c-subscribe__products-item {
  padding: 10px;
}

.c-subscribe__card {
  width: 280px;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  color: #3e2623;
}

.c-subscribe__card-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  color: #221e35;
}

.c-subscribe__card-content {
  margin: 1em 0;
  font-size: 12px;
  text-align: left;
}

.c-subscribe__card-button {
  margin-top: auto;
}

.c-subscribe__card-wrapper {
  padding: 20px;
}

.c-subscribe__connect-link {
  color: white;
  font-size: 12px;
  text-decoration: underline;
}