:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-image-grid {
  position: relative;
  display: table;
  width: 100%;
  margin: 30px auto;
  color: #3e2623;
}

.c-image-grid__grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
}

.c-image-grid__image {
  display: block;
}

.c-image-grid__cell {
  position: relative;
}

.c-image-grid__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  margin: 0;
  opacity: 0;
}

.c-image-grid__input:not([disabled]) {
  cursor: pointer;
}

.c-image-grid__check {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  background: rgba(224, 50, 28, 0.5);
}

.c-image-grid__input:checked + .c-image-grid__check {
  opacity: 1;
}