:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-game-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #ffffff;
  overflow: hidden;
}

.c-game-layout__header {
  flex: none;
  height: 48px;
  background: linear-gradient(270deg, #221e35 0%, #221e35 100%);
}

.c-game-layout__main {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: auto;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 0;
  background: linear-gradient(to top left, #221e35 0%, #221e35 100%);
  scroll-behavior: smooth;
}

.c-game-layout--full {
  background: linear-gradient(to bottom, #1ca6fc 50px, #fc388c 100%);
}

.c-game-layout--full .c-game-layout__main {
  background: none;
}

.c-game-layout--full .c-game-layout__header {
  background: none;
}

.c-game-layout__main .leaflet-container {
  font-family: "Miriam", Arial, Helvetica, sans-serif;
}

.c-game-layout__nav {
  flex: none;
  color: #b3bec2;
}