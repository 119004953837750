:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-spot-preview {
  position: relative;
  width: 300px;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  color: #3e2623;
}

.c-spot-preview__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}

.c-spot-preview__content {
  min-width: 250px;
}

.c-spot-preview__top-line {
  height: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #e0321c, #e0321c);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.c-spot-preview__title {
  margin: 0;
  color: #221e35;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

.c-spot-preview__flag + .c-spot-preview__wrapper .c-spot-preview__title {
  max-width: calc(100% - 85px);
}

.c-spot-preview__sub-title {
  max-width: calc(100% - 85px);
  margin-bottom: 5px;
  color: #9b9b9b;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.c-spot-preview__info {
  display: flex;
  align-items: center;
  margin-top: 5px;
  line-height: 19px;
  font-size: 14px;
}

.c-spot-preview__info small {
  font-size: inherit;
  white-space: nowrap;
}

.c-spot-preview__info a {
  color: inherit !important;
  white-space: nowrap;
  text-decoration: underline;
}

.c-spot-preview__warning {
  color: #1860aa;
  align-items: center;
  margin-top: 15px;
}

.c-spot-preview__warning-icon {
  flex: none;
  margin-right: 6px;
}

.c-spot-preview__icon-wrapper {
  flex: none;
  width: 20px;
  margin-right: 4px;
  text-align: center;
}

.c-spot-preview__icon {
  display: inline-block;
  vertical-align: middle;
  color: #3cdbe2;
  width: 16px;
  height: auto;
}

.c-spot-preview__button {
  text-align: center;
  padding-top: 14px;
  margin-top: auto;
}

.c-spot-preview__flag {
  position: absolute;
  top: 0;
  right: 20px;
}

.c-spot-preview__closer {
  text-align: left;
  color: #1860aa;
}

.c-spot-preview__link {
  color: #e0321c !important;
  text-decoration: underline;
}

.c-spot-preview__activate {
  display: inline;
  border: none;
  outline: none;
  color: #e0321c;
  text-decoration: underline;
  background: none;
  margin: 0;
  padding: 0;
}