:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-account-edit {
  width: 100%;
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
}

.c-account-edit__title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
}

.c-account-edit__sub-title {
  margin-bottom: 8px;
  font-size: 10px;
  font-family: sans-serif;
  text-align: center;
}

.c-account-edit__chapter {
  display: flex;
  align-items: baseline;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  margin: 30px 0 20px;
}

.c-account-edit__chapter::before,
.c-account-edit__chapter::after {
  content: "";
  display: block;
  flex: auto;
  height: 0;
  border-bottom: 1px white solid;
}

.c-account-edit__chapter::before {
  margin-right: 1em;
}

.c-account-edit__chapter::after {
  margin-left: 1em;
}

.c-account-edit__input {
  width: 100%;
  border-radius: 22px;
  border: none;
  height: 40px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  outline: none;
  color: #3e2623;
  display: block;
  margin-bottom: 15px;
}

.c-account-edit__input::placeholder {
  color: #9b9b9b;
}

.c-account-edit__button {
  background-color: #31115b;
}

.c-account-edit__button-disabled {
  background: #cccccc;
}

.c-account-edit__error {
  color: white;
  font-weight: bold;
  font-size: 10px;
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 8px;
}