:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-product {
  padding: 10px;
}

.c-product-card {
  position: relative;
  width: 280px;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  color: #3e2623;
}

.c-product__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}

.c-product__top-line {
  height: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.c-product__title {
  margin: 0;
  color: #221e35;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
}

.c-product__content {
  display: flex;
  margin: 1em 0;
  align-items: center;
  font-size: 12px;
  text-align: left;
}

.c-product__icon {
  margin-right: 10px;
  flex: none;
}

.c-product__left {
  margin-right: 20px;
  color: #221e35;
  text-align: center;
}

.c-product__left-big {
  font-size: 50px;
  line-height: 50px;
}

.c-product__button {
  margin-top: auto;
}