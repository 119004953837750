:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-connexion-gate__about {
  color: #431973;
  font-size: 14px;
}

.c-connexion-gate__logo {
  width: 100px;
}

.c-header__logo {
  height: 36px;
}

html .c-rich-content__paper {
  font-family: RoughTypewriter;
  font-size: 14px;
}

.c-unpixelate-image__canvas {
  border: 1px solid #03465f;
  box-shadow: 5px 5px 0 0 #03465f;
}

.o-button,
.o-button:any-link {
  color: #fff;
  background: #e0321c;
}

.o-tag {
  background-color: #1860aa;
}

.c-footer-riddle-nav__link,
.c-header__small,
.c-footer-nav__link,
.c-game-layout {
  color: #fff;
}

.c-spot-preview__icon,
.c-spot__title,
.c-story-progress-box__title {
  color: #1860aa;
}

.c-spot__answer-text {
  white-space: pre-wrap;
}

.c-footer-riddle-nav {
  background: #1a215c;
}

.c-footer-riddle-nav__link {
  color: #fff;
}

.c-footer-riddle-nav__answer-legend {
  line-height: 1.25;
}

.c-footer-riddle-nav__answer-icon > path {
  fill: #fff;
}

.c-footer-nav__link--active::after {
  background: #e0321c;
}

.c-autologin__text {
  color: #000;
}

.c-autologin__about {
  color: #222e67;
}

.c-autologin__text {
  color: #fff;
}

.c-about__sub-title {
  display: none;
}