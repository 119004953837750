:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-mixt-carousel {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-mixt-carousel .carousel .slider-wrapper {
  width: calc(100% - 80px);
}

.c-mixt-carousel__figure {
  display: table;
  margin: 0 auto;
  position: relative;
}

.c-mixt-carousel__image {
  display: block;
}

.c-mixt-carousel__watermark {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-shadow: -1px 1px 0 black;
}

.c-mixt-carousel .carousel .slide {
  background: white;
}

.c-mixt-carousel .carousel .slide img {
  width: auto;
}

.c-mixt-carousel .carousel img {
  width: auto;
}

.c-mixt-carousel__title {
  margin-bottom: 5px;
  margin-top: 5px;
}

.c-mixt-carousel__title:empty {
  display: none;
}

.c-mixt-carousel__list {
  font-size: 12px;
  text-align: left;
  margin-top: 20px;
}

.c-mixt-carousel__list:empty {
  display: none;
}

.c-mixt-carousel__text {
  font-size: 12px;
  text-align: justify;
  margin-top: 10px;
}

.c-mixt-carousel__text:empty {
  display: none;
}

.c-mixt-carousel .carousel .control-arrow {
  display: flex;
  flex-direction: column;
  opacity: 1;
  justify-content: center;
}

.c-mixt-carousel .carousel .control-arrow.control-disabled {
  display: none;
}

.c-mixt-carousel .carousel .control-arrow::before {
  width: 24px;
  height: 24px;
  border: none;
}

.c-mixt-carousel .carousel .control-arrow:hover {
  background: none;
}

.c-mixt-carousel .control-arrow.control-next::before {
  background: url("/images/arrow-right.svg") no-repeat;
}

.c-mixt-carousel .control-arrow.control-prev::before {
  background: url("/images/arrow-left.svg") no-repeat;
}

.c-mixt-carousel__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  padding-top: 5px;
  border: 1px solid #221e35;
}

.c-mixt-carousel__content--middle {
  justify-content: center;
}

.c-mixt-carousel__content--bottom {
  justify-content: flex-end;
}