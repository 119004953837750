.c-list-selection__list {
  padding: 0;
  margin: 14px 0;
  list-style: none;
}

.c-list-selection__label {
  display: inline-block;
  margin-left: 10px;
}

.c-list-selection__control {
  display: block;
  padding: 5px 0;
  cursor: pointer;
}
