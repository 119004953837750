:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-messages-list {
  margin-top: 20px;
}

.c-messages-list > a {
  text-decoration: none;
  color: black;
}

.c-messages-list__item {
  padding: 10px 20px;
  margin-right: -20px;
  margin-left: -20px;
  border-bottom: solid 1px #ddd;
}

.c-messages-list__item--read {
  background-color: #eee;
}

.c-messages-list__item-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.c-messages-list__item-subject {
  text-transform: capitalize;
}

.c-messages-list__item--read > .c-messages-list__item-header {
  font-weight: normal;
}

.c-messages-list__item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 50px;
}