:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.clue-button {
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.clue-malus {
  font-size: 0.8rem;
}

.clue-strong {
  font-weight: bold;
  color: #221e35;
}