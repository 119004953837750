:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-rule__card {
  margin: 20px 0 0;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  color: #3e2623;
}

.c-rule__title {
  margin: 0;
  color: #221e35;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

.c-rule__content {
  display: flex;
  align-items: center;
  margin: 1em 0;
  font-size: 12px;
}

.c-rule__icon {
  margin-right: 20px;
  width: 60px;
  flex: none;
}

.c-rule__icon--fit {
  width: auto;
}