:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-xp-flag {
  height: 51px;
  width: 74px;
  background: linear-gradient(to top left, #221e35 0%, #221e35 100%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-xp-flag__xp {
  margin-top: 10px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.c-xp-flag__malus {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.c-stories__answered-label {
  color: white;
  font-size: 8px;
  font-family: sans-serif;
  line-height: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.c-stories__answered-xp {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.c-xp-flag__star {
  width: 12px;
  height: 12px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 11px;
  margin-top: 3px;
}