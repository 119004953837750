:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-story-progress-box {
  display: flex;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #e6e6f0;
  background-color: white;
  flex: none;
}

.c-story-progress-box--completed {
  background-image: url("/images/stamp-check.svg");
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: center right 20%;
}

.c-story-progress-box__logo {
  flex: none;
  height: 60px;
  padding-right: 10px;
}

.c-story-progress-box__logo img {
  border-radius: 10px;
  height: 100%;
}

.c-story-progress-box__text {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-story-progress-box__title {
  margin: 0;
  font-size: 18px;
  color: #221e35;
  line-height: 1.25;
}

.c-story-progress-box__subtitle {
  font-size: 14px;
  color: #3e2623;
  line-height: 1.25;
}

.c-story-progress-box__cross {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border: 0;
  background: none;
  cursor: pointer;
}

.c-story-progress-box__cross svg {
  display: block;
}

.c-story-progress-box__info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: #e0321c;
  font-size: 14px;
  line-height: 1;
}

.c-story-progress-box__icon {
  margin-right: 5px;
}

.c-story-progress-box__icon--pagaille {
  margin-right: 0;
}

.c-story-progress-box__icon--inactive {
  filter: grayscale(1);
  opacity: 0.25;
}

.c-story-progress-box__icon:not(:first-child) {
  margin-left: 5px;
}

.c-story-progress-box__answered {
  color: #e0321c;
  margin-left: 10px;
}

progress,
progress::-webkit-progress-bar {
  width: 80px;
  background: #eeeeee;
  border-radius: 5px;
  height: 8px;
}

progress::-webkit-progress-value {
  border-radius: 5px;
  background: linear-gradient(to right, #64cdf4, #b8e986);
}

progress::-moz-progress-bar {
  border-radius: 5px;
  background: linear-gradient(to right, #64cdf4, #b8e986);
}