@import '~styles/variables.scss';

@mixin background {
  background: linear-gradient(135deg, $color-main, $color-main2);
  color: white;
}

@mixin background__menu {
  background: linear-gradient(90deg, $color-main, $color-main2);
}

@mixin background__button {
  @include background();
}

@mixin background__button--alternate {
  background: $color-popup-gradient-top;
  color: $color-main;
}

@mixin background__spot {
  background: linear-gradient(135deg, $color-main, $color-main--light);
}

@mixin background__modal-overlay {
  background: linear-gradient(135deg, $color-main, rgba($color-main2, 0.8));
}
