:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-stats {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 0 20px;
}

.c-stats__header {
  text-align: center;
  padding: 20px 0;
}

.c-stats__title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.c-stats__title-2 {
  margin-top: 40px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.c-stats__section {
  padding-top: 20px;
}

.c-stats__sub-title {
  margin-bottom: 8px;
  font-size: 10px;
  font-family: sans-serif;
}

.c-stats__sub-title svg {
  display: inline-block;
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
}

.c-stats__link {
  display: block;
  background: none;
  border: 0;
  padding: 1px;
  margin: 0 auto;
  font: inherit;
  color: inherit;
  font-size: 10px;
  font-family: sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.c-stats__stats-grid {
  display: flex;
  align-items: flex-end;
  max-width: 480px;
  margin: 0 auto;
}

.c-stats__stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.c-stats__stats-value {
  font-size: 25px;
  color: white;
}

.c-stats__stats-label {
  font-size: 10px;
  font-family: sans-serif;
  line-height: 16px;
  height: 32px;
}

.c-stats__stats-label strong {
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.c-stats__stats-link {
  text-decoration: underline;
}

.c-stats__stats-item:hover .c-stats__stats-link,
.c-stats__stats-item:focus .c-stats__stats-link {
  color: #fff;
}

.c-stats__user {
  color: #fff;
}

.c-stats__rank-table {
  margin: 20px auto;
  width: 100%;
  max-width: 320px;
  border-collapse: collapse;
}

.c-stats__rank-cell {
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.1);
  background-clip: padding-box;
  border-top: 1px solid transparent;
}

.c-stats__rank-cell:nth-child(3) {
  text-align: right;
}

.c-stats__rank-cell:nth-child(1)::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-right: 5px;
  background: #fff;
  opacity: 0;
}

.c-stats__user .c-stats__rank-cell:nth-child(1)::before {
  opacity: 1;
}

.c-stats__rank-ellipsis {
  text-align: center;
}

.c-stats__notif {
  flex: none;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
}