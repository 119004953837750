:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-connexion-gate {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  height: 100%;
  text-align: center;
  background: linear-gradient(135deg, #221e35, #221e35);
  color: white;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.c-connexion-gate__header {
  max-width: 480px;
  margin: 0 auto;
  padding-top: 45px;
}

.c-connexion-gate__logo {
  width: 52px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.c-connexion-gate__header-text {
  width: 90px;
}

.c-connexion-gate__form {
  max-width: 480px;
  margin: 15px auto 0;
}

.c-connexion-gate__form input {
  width: 100%;
  border-radius: 22px;
  border: none;
  height: 40px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  outline: none;
  color: #3e2623;
}

.c-connexion-gate__form input:not(:last-of-type) {
  margin-bottom: 15px;
}

.c-connexion-gate__form input::placeholder {
  color: #9b9b9b;
}

.c-connexion-gate__error {
  color: #e0321c;
  font-size: 12px;
  text-align: center;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 8px;
}

.c-connexion-gate__form input.c-connexion-gate__input-error {
  border: #e0321c solid 1px;
}

.c-connexion-gate__notice {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 220px;
  margin: 30px auto 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.c-connexion-gate__about {
  margin-top: auto;
  margin-bottom: 25px;
  color: #e0321c;
  text-decoration: underline;
  font-size: 10px;
  font-family: sans-serif;
  font-weight: normal;
}

.c-connexion-gate__notice-icon {
  flex: none;
  margin-right: 10px;
}

.c-connexion-gate__link {
  padding-top: 20px;
  text-decoration: underline;
  font-size: 10px;
  font-family: sans-serif;
  background: none;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}