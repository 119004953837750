@import '~styles/variables.scss';

.o-laurel {
  position: relative;
  padding: 0 25px;
  margin-bottom: 15px;
  font-size: 30px;
}

.o-laurel--smaller {
  margin-bottom: 0;
  margin-top: 15px;
  transform: scale(0.8, 0.8);
}

.o-laurel::before,
.o-laurel::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.o-laurel::before {
  left: 0;
  background-image: url('/images/laurel-left.svg');
}

.o-laurel::after {
  right: 0;
  background-image: url('/images/laurel-right.svg');
}
