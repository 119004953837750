:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-page {
  height: 100%;
  overflow-y: auto;
  background-color: white;
  color: #3e2623;
  font-size: 14px;
  z-index: 1;
}

.c-page:after {
  position: absolute;
  bottom: 0;
  content: "";
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  height: 80px;
  width: 100%;
  z-index: 2;
  pointer-events: none;
}

.c-page__scroll {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.c-page__container {
  height: 100%;
  position: relative;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.c-page__content {
  padding-bottom: 80px;
}

.c-page__title {
  max-width: none;
  text-align: center;
}