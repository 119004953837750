:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-spot {
  height: 100%;
}

.c-spot__flag {
  position: absolute;
  top: 0;
  right: 20px;
}

.c-spot__title {
  margin: 0;
  line-height: 24px;
  font-size: 18px;
  color: #221e35;
  max-width: calc(100% - 83px);
}

.c-spot__title-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: text-top;
  margin-left: 0.5em;
  color: #3cdbe2;
}

.c-spot__subtitle {
  margin: 0;
  margin-top: 3px;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  max-width: calc(100% - 83px);
}

.c-spot__content > img {
  display: block;
  margin: auto;
  margin-top: 30px;
}

.c-spot__content {
  margin-top: 20px;
}

.c-spot__hint {
  background-color: rgba(238, 238, 249, 0.6);
  margin-top: 20px;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  line-height: 20px;
}

.c-spot__hint-title {
  color: #221e35;
  font-weight: bold;
}

.c-spot__hint-content {
  color: #3e2623;
  line-height: 23px;
}

.c-spot__warning {
  border: 1px solid #e0321c;
  margin-top: 20px;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  line-height: 20px;
  color: #e0321c;
}

.c-spot__warning-icon {
  margin-right: 8px;
  height: 20px;
  vertical-align: text-bottom;
}

.c-spot__section-title {
  color: #221e35;
  font-size: 18px;
  margin-top: 33px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}

.c-spot__section-title-icon {
  display: inline-block;
  margin-right: 12px;
}
.c-spot__section-title-icon > svg {
  display: block;
}

.c-spot__answer-list {
  list-style: none;
  padding: 0;
}

.c-spot__answer-item {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.c-spot__answer-separator {
  flex: none;
  width: 7px;
  height: 7px;
  margin-top: 0.5em;
  margin-right: 12px;
  background: #221e35;
  border-radius: 5px;
}

.c-spot__answer-cheer {
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgba(238, 238, 249, 0.6);
}

.c-spot__clues-list {
  list-style: none;
  padding: 0;
}

.c-spot__clues-item {
  display: flex;
  justify-content: flex-start;
  margin-top: 17px;
}

.c-spot__clues-clue a {
  color: inherit;
  text-decoration: underline;
}

.c-spot__clues-index {
  color: #221e35;
  font-weight: bold;
  margin-right: 8px;
  min-width: 10px;
}

.c-spot__clues-separator {
  flex: none;
  width: 7px;
  margin-right: 12px;
  background: linear-gradient(to bottom, #1860aa 0%, #1860aa 100%);
  border-radius: 5px;
}

.c-spot__problem-title {
  color: #e0321c;
  font-weight: bold;
  margin-top: 20px;
}

.c-spot__problem-button {
  border: none;
  background: none;
  padding: 0;
  color: #e0321c;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}

.c-spot__problem-text {
  margin-top: 6px;
}

.c-spot__problem-send-button {
  font-size: 12px;
  min-height: 30px;
}

.c-spot__problem-message {
  height: 100px;
  width: 100%;
  border-radius: 22px;
  border: 1px solid #d8d8d8;
  padding: 20px;
  color: #3e2623;
  font-size: 16px;
  outline: none;
}

.c-spot__problem-message::placeholder {
  color: #9b9b9b;
}

.c-spot__problem-cancel-button {
  border: none;
  background: none;
  outline: none;
  color: #3e2623;
  text-decoration: underline;
  padding: 0;
  font-size: 12px;
  margin-right: 10px;
}

.c-spot__problem-buttons {
  margin-left: auto;
  margin-right: 1px;
  text-align: right;
}

.c-spot__problem-button {
  background: none;
  border: none;
  outline: none;
}