:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-badge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 15px 2px 0;
}

.c-badge__icon {
  position: relative;
  display: block;
  margin: 0 auto 15px;
  width: 62px;
  height: 40px;
  border-radius: 8px;
}
.c-badge__icon::before, .c-badge__icon::after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.c-badge__icon::before {
  transform: rotate(60deg);
}
.c-badge__icon::after {
  transform: rotate(-60deg);
}

.c-badge__circle {
  width: 50px;
  height: 50px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.c-badge__circle img {
  max-width: 70%;
}

.c-badge__bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: #221e35 solid 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  position: absolute;
  right: 4px;
  color: #3e2623;
  background-color: white;
  bottom: -15px;
  font-size: 12px;
}

.c-badge__label {
  display: block;
  font-size: 10px;
  font-family: sans-serif;
  text-align: center;
}