:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-map__recenter {
  border: 0;
  cursor: pointer;
}

.c-map__recenter-target,
.c-map__recenter-planet,
.c-map__recenter-squircle {
  position: absolute;
  z-index: 510;
}

.c-map__recenter-target {
  display: block;
  width: 24px;
  height: auto;
  right: 27px;
  bottom: 28px;
}

.c-map__recenter-planet {
  display: block;
  width: 30px;
  height: 30px;
  right: 25px;
  bottom: 26px;
}

.c-map__recenter-squircle {
  display: block;
  width: 48px;
  height: 48px;
  right: 15px;
  bottom: 16px;
}

/* LEAFLET Reset */
.leaflet-popup-content {
  margin: 0 !important;
}

.leaflet-popup-content-wrapper {
  box-shadow: none !important;
  padding: 0 !important;
}

.leaflet-popup-close-button {
  display: none !important;
}

.leaflet-popup-tip {
  box-shadow: none !important;
}

.leaflet-container {
  background: #f1f3fe !important;
}