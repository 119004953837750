.c-textual-image {
  margin: 15px 0;
}

.c-textual-image__image {
  display: block;
}

.c-textual-image__text a {
  color: inherit;
  text-decoration: underline;
}
