:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-review-modal__actions {
  display: flex;
  justify-content: center;
}

.c-review-modal__button {
  border: none;
  background: none;
  outline: none;
}

.c-review-modal__link {
  color: #221e35;
  text-decoration: underline;
}

.c-review-modal__message {
  height: 100px;
  width: 100%;
  border-radius: 22px;
  border: 1px solid #d8d8d8;
  padding: 20px;
  color: #3e2623;
  font-size: 16px;
  outline: none;
}

.c-review-modal__message::placeholder {
  color: #9b9b9b;
}

.c-review-modal__send {
  display: flex;
  justify-content: flex-end;
}