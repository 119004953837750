.c-zoomable-image {
  margin: 14px -20px;
}

.c-zoomable-image__canvas {
  display: block;
  width: 100%;
}

.c-zoomable-image__action {
  text-align: center;
}

.c-zoomable-image__download-link {
  font: inherit;
  font-size: 12px;
  color: inherit;
  text-decoration: underline;
}

.c-zoomable-image__unzoom-link {
  border: 0;
  padding: 0;
  background: none;

  font: inherit;
  font-size: 12px;
  color: inherit;
  text-decoration: underline;
}
