:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-header {
  position: relative;
  display: flex;
  line-height: 16px;
  height: 100%;
}

.c-header__back {
  display: flex;
  align-items: center;
  margin-right: auto;
  text-align: left;
}

.c-header__user {
  display: flex;
  align-items: center;
  margin-left: auto;
  text-align: right;
}

.c-header__back-button {
  border: 0;
  padding: 10px;
  background: none;
  cursor: pointer;
}

.c-header__back-button svg {
  height: 17px;
  width: 17px;
}

.c-header__logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  width: 38px;
  height: 38px;
  margin: auto;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.c-header__logo img {
  display: block;
}

.c-header__menu-icon {
  padding: 10px;
  padding-left: 0;
}

.c-header__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  text-transform: capitalize;
  text-align: right;
}

.c-header__text--left {
  text-transform: none;
  text-align: left;
}

.c-header__small--left {
  font-size: 10px;
  font-family: sans-serif;
}

.c-header__link {
  display: flex;
  align-items: center;
  height: 100%;
  border: 0;
  background: 0;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.c-header__link:focus {
  outline: none;
}

.c-header__highlight {
  color: #fff;
}

.c-header__user .c-header__highlight {
  max-width: 30vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-header__small {
  color: white;
  line-height: 16px;
}

.c-header__spot-icon {
  vertical-align: middle;
  margin: -1px 8px -1px 1px;
}

.c-header__menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 180px;
  z-index: 610;
}

.c-header__menu--open {
  display: block;
}

.c-header__menu-list {
  padding: 5px 0;
  background-image: linear-gradient(270deg, #221e35 0%, #221e35 100%);
  background-size: 100vw auto;
  background-position: center right;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.c-header__menu--full-page .c-header__menu-list {
  background-image: none;
  background-color: #1ca6fc;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.c-header__menu-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}

.c-header__menu-link {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 20px;
  background: none;
  border: 0;
  font: inherit;
  color: inherit;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
}

.c-header__menu-link:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.c-header__menu--full-page .c-header__menu-link:not(:last-child)::after {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}