:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-about {
  width: 100%;
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
}

.c-about__title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

.c-about__sub-title {
  margin-bottom: 8px;
  font-size: 10px;
  font-family: sans-serif;
  text-align: center;
}

.c-about__button {
  margin: 0 5px;
}

.c-about__chapter {
  display: flex;
  align-items: baseline;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  margin: 30px 0 20px;
}

.c-about__chapter::before,
.c-about__chapter::after {
  content: "";
  display: block;
  flex: auto;
  height: 0;
  border-bottom: 1px white solid;
}

.c-about__chapter::before {
  margin-right: 1em;
}

.c-about__chapter::after {
  margin-left: 1em;
}

.c-about__social-link {
  display: inline-flex;
  margin: 3px;
  border-radius: 50%;
  background: #2c075b;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  transition: background-color 300ms;
}

.c-about__content a {
  color: white;
}