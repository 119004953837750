.c-filter-image {
  margin: 1em 0;
}

.c-filter-image__wrapper {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #333333;
  overflow: hidden;
}

.c-filter-image__image {
  display: block;
}

.c-filter-image__filter,
.c-filter-image__appear {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 101%;
  max-width: none;
}

.c-filter-image__action {
  display: flex;
  justify-content: flex-end;
}

.c-filter-image__input {
  border: 1px solid #666666;
  padding: 0;
  width: 60px;
  height: 40px;
  margin-right: 10px;
  background: white;

  font: inherit;
  cursor: pointer;
}

.c-filter-image__input:focus {
  outline: none;
}
