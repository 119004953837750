:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-spot-marker__content {
  padding: 10px;
  text-align: center;
}

/* pulse wave */
@keyframes c-spot-pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
/* Stack order */
.c-spot-marker__icon {
  z-index: 1005 !important;
  border-radius: 50%;
}

.c-spot-marker__icon--secondary {
  z-index: 1000 !important;
}

.c-spot-marker__icon--completed {
  z-index: 1002 !important;
}

.c-spot-marker__icon--ongoing {
  z-index: 1007 !important;
}

.c-spot-marker__icon--open {
  z-index: 1010 !important;
}

.c-spot-marker__icon--light {
  opacity: 0.5;
}

.c-spot-marker__icon--highlighted::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: rgba(245, 184, 35, 0.3);
  border-radius: 50%;
}

/* Bubble */
.c-spot-marker__icon-bubble {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  min-width: 8px;
  height: 10px;
  min-height: 8px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  background: currentColor;
}

.c-spot-marker__icon-bubble svg {
  display: none;
  width: 8px;
  max-width: calc(100% - 2px);
  height: auto;
}

.c-spot-marker__icon--ongoing .c-spot-marker__icon-dot {
  display: block;
}

.c-spot-marker__icon--answered .c-spot-marker__icon-check {
  display: block;
}

.c-spot-marker__icon--blocked .c-spot-marker__icon-cross {
  display: block;
}

.c-spot-marker__icon--completed .c-spot-marker__icon-dot {
  display: none;
}

.c-spot-marker__icon--secondary .c-spot-marker__icon-bubble {
  background: #bebed2 !important;
}

.c-spot-marker__icon--open .c-spot-marker__icon-bubble {
  background: white;
  width: 20px !important;
  height: 20px !important;
}

/* Pulse */
.c-spot-marker__icon-pulse {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  margin: auto;
  border-radius: 50%;
  background-color: currentColor;
  animation: c-spot-pulsate 1.5s infinite;
}

.c-spot-marker__icon--secondary .c-spot-marker__icon-pulse {
  background-color: rgba(190, 190, 210, 0.8);
}

.c-spot-marker__icon--open .c-spot-marker__icon-pulse {
  display: none;
}

/* Open circle */
.c-spot-marker__icon-border {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 28px;
  height: 28px;
  margin: auto;
  border-radius: 50%;
  background: currentColor;
}

.c-spot-marker__icon--open .c-spot-marker__icon-border {
  display: block;
}

.c-spot-marker__icon--secondary .c-spot-marker__icon-border {
  background: #bebed2;
}