:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-forgotten-password__input {
  width: 100%;
  border-radius: 22px;
  border: #221e35 solid 1px;
  height: 40px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  outline: none;
  color: #3e2623;
  margin-top: 20px;
}

.c-forgotten-password__input input::placeholder {
  color: #9b9b9b;
}

.c-forgotten-password__error {
  color: #e0321c;
  font-size: 12px;
  padding-bottom: 8px;
}

.c-forgotten-password__form input.c-forgotten-password__input-error {
  border: #e0321c solid 1px;
}