:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-rich-content__file {
  color: #3e2623;
  display: flex;
  align-items: center;
}

.c-rich-content__file svg {
  width: 45px;
  height: 45px;
}

.c-rich-content__audio-image {
  width: 50%;
  display: block;
  margin: 20px auto;
}

.c-rich-content__audio-link {
  margin-bottom: 1.5em;
  text-align: center;
}

.c-rich-content__audio-link a {
  font-size: 90%;
  color: inherit;
  text-decoration: underline;
}

.c-rich-content__audio-player,
.c-rich-content__video-player {
  width: 100%;
}

.c-rich-content__image-source {
  display: block;
  color: #666666;
  text-align: center;
  font-size: 12px;
  font-family: sans-serif;
}

.c-rich-content__image-source a {
  color: inherit;
  text-decoration: underline;
}

.c-rich-content__download-link {
  color: #666666;
  font: inherit;
  font-size: 12px;
  text-decoration: underline;
}

.c-rich-content__panorama {
  position: relative;
  margin: 20px -20px;
}

.c-rich-content__panorama::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 40px;
  background: linear-gradient(to right, white 20%, rgba(255, 255, 255, 0));
}

.c-rich-content__panorama::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 40px;
  background: linear-gradient(to left, white 20%, rgba(255, 255, 255, 0));
}

.c-rich-content__panorama-caret {
  display: flex;
  overflow-x: scroll;
}

.c-rich-content__panorama-image {
  display: block;
  width: auto;
  max-width: none;
  padding: 0 40px;
}

.c-rich-content__panorama-surprise {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  flex: none;
  margin-right: 40px;
}

.c-rich-content__image-wall {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.c-rich-content__image-wall-item {
  max-width: 100%;
  padding: 3px;
}

.c-rich-content__paper {
  border: 1px solid #d567e6;
  padding: 10px;
  margin: 20px auto;
  color: black;
  font-family: monospace, monospace;
  font-size: 14px;
}

.c-spot--resistance .c-rich-content__paper {
  font-family: RoughTypewriter, serif;
  font-weight: bold;
  font-size: 16px;
}

.c-spot--pagaille .c-rich-content__paper {
  font-family: Stormblade, serif;
  font-size: 20px;
  font-weight: normal;
  word-spacing: 0.3em;
}

.c-rich-content__click-to-see-action {
  display: table;
  margin: auto;
}

.c-rich-content__click-to-see-content {
  display: none;
}

.c-zoom__anchor {
  position: relative;
}

.c-zoom__button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

.c-rich-content__table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}