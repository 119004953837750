.o-tag {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  height: 18px;
  padding: 0 9px;
  border-radius: 9px;
  background-color: $color-main;

  color: white;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
}

.o-tag--small {
  height: 14px;
  padding: 0 5px;
  border-radius: 7px;

  font-family: sans-serif;
  font-size: 8px;
  line-height: 12px;
}

.o-tag--upper {
  text-transform: uppercase;
}
