.c-connected-links {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-connected-links--border {
  border: 1px black solid;
}

.c-connected-links__svg {
  display: block;
}

.c-connected-links__dot {
  cursor: pointer;
}

.c-connected-links__link {
  pointer-events: none;
}

.c-connected-links__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(black, 0.4);
  pointer-events: none;

  color: white;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bold;
  font-family: 'RoughTypewriter', serif;
}
