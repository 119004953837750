:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 610;
  background: linear-gradient(135deg, #221e35, rgba(34, 30, 53, 0.8));
}

.c-modal {
  position: relative !important;
  padding: 0 !important;
  width: 90% !important;
  max-height: calc(100% - 80px) !important;
  margin: 0 auto;
  border: 0 !important;
  border-radius: 0 !important;
  outline: none !important;
}

.c-modal__strong {
  font-weight: bold;
  color: #221e35;
}

.c-modal--content {
  display: flex;
  flex-direction: column;
  padding: 0 20px !important;
  max-width: 480px;
  border-radius: 12px !important;
  background-color: white !important;
}

.c-modal--content::before {
  display: block;
  content: "";
  margin-bottom: 20px;
}

.c-modal--content::after {
  display: block;
  content: "";
  margin-top: 20px;
}

.c-modal__header {
  flex: none;
  display: flex;
  padding-bottom: 10px;
  margin: 0 30px 0 0;
  color: #221e35;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.35;
}

.c-modal__header:empty {
  display: none;
}

.c-modal__header-icon {
  margin-right: 12px;
}

.c-modal__cross {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  z-index: 10;
  border: 0;
  background: none;
  cursor: pointer;
}

.c-modal__cross-icon {
  display: block;
}

.c-modal__content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: auto;
  z-index: 1;
  min-height: 0;
}

.c-modal__scroll {
  flex: auto;
  min-height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.c-modal__panel {
  color: #3e2623;
  font-size: 14px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.c-modal__panel::before {
  content: "";
  display: block;
  margin-bottom: 20px;
}

.c-modal__panel::after {
  content: "";
  display: block;
  margin-top: 20px;
}

.c-modal__content:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 100%;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0));
  pointer-events: none;
}

.c-modal__content:after {
  content: "";
  position: absolute;
  bottom: 0;
  z-index: 2;
  height: 20px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 20%, white);
  pointer-events: none;
}

.c-modal__footer {
  flex: none;
}

.c-modal-story__pitch,
.c-modal-spot__pitch {
  margin-top: 15px;
  font-size: 14px;
}

.c-modal-story__image {
  display: block;
  margin: 10px auto 0;
  max-height: 100px;
}

.c-modal-spot__image {
  display: block;
  margin: 10px auto;
  height: 100px;
}

.c-modal-award__reward {
  font-size: 20px;
  font-weight: bold;
  color: #221e35;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.c-modal__top-line {
  height: 8px;
  margin-bottom: 13px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.c-modal__universe-logo {
  position: absolute;
  top: -29px;
  left: calc(50% - 36px);
  width: 72px;
  margin-bottom: 10px;
}

.c-modal__story-preview {
  margin-top: 30px;
  border-bottom: #d8d8d8 solid 1px;
  padding-bottom: 5px;
}

.c-modal-story-completed {
  background-image: url("/images/stamp-check.svg");
  background-size: 190px;
  background-repeat: no-repeat;
  background-position: top -20px center;
}

.c-modal__story-preview .c-story-preview.c-story-preview--completed .c-story-preview__title {
  opacity: 1;
}

.c-modal__title {
  color: #221e35;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.c-modal__title {
  margin-top: 40px;
}

.c-modal-story .c-modal__title,
.c-modal-story-completed .c-modal__title {
  margin-top: 15px;
}

.c-modal-story-completed .c-modal__content::before,
.c-modal-story-completed .c-modal__panel::before {
  display: none;
}

.c-modal-story__warning {
  color: #e0321c;
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.c-modal-story__warning svg {
  flex: none;
  margin-right: 6px;
}

.c-modal__link {
  color: #221e35;
  text-decoration: underline;
}