:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-footer-riddle-nav {
  position: relative;
  z-index: 3;
  background: linear-gradient(to right, #fff, #e0321c);
}

.c-footer-riddle-nav__list {
  position: relative;
  display: flex;
  height: 64px;
  max-width: 600px;
  padding: 0;
  margin: 0 auto;
  list-style: none;
}

.c-footer-riddle-nav__item {
  width: calc(50% - 40px);
}

.c-footer-riddle-nav__item-light {
  opacity: 0.4;
}

.c-footer-riddle-nav__item:last-child {
  margin-left: 80px;
}

.c-footer-riddle-nav__item:last-child {
  margin-left: auto;
}

.c-footer-riddle-nav__item:only-child {
  margin-right: auto;
}

.c-footer-riddle-nav__answer {
  position: absolute;
  top: -32px;
  left: calc(50% - 40px);
}

.c-footer-riddle-nav__answer-background {
  width: 80px;
  height: 80px;
}

.c-footer-riddle-nav__link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  padding-top: 15px;
  border: 0;
  background: transparent;
  color: #221e35;
  text-transform: uppercase;
  font-size: 8px;
  font-family: sans-serif;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

.c-footer-riddle-nav__link svg {
  display: block;
  margin: 0 auto;
}

.c-footer-riddle-nav__answer-button {
  border: 0;
  padding: 0;
  background: transparent;
  color: white;
  text-transform: uppercase;
  font-size: 8px;
  font-family: sans-serif;
  font-weight: bold;
  position: relative;
  outline: none;
}

.c-footer-riddle-nav__answer-legend {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.c-footer-riddle-nav__answer-icon {
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
  text-align: center;
}