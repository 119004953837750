:export {
  main: #221e35;
  main2: #221e35;
  mainLight: #d567e6;
  mainDark: #e0321c;
  alert: #e0321c;
  highlight: #fff;
  highlight2: #fff;
  recentFrom: #1860aa;
  recentTo: #1860aa;
  activeFrom: #fff;
  activeTo: #e0321c;
  iconGradient: #fff;
}

.c-gift-input {
  width: 100%;
  border-radius: 22px;
  border: #410b7a solid 1px;
  height: 40px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  outline: none;
  color: #4a4a4a;
}

.c-gift-error {
  color: #e0321c;
  font-size: 12px;
  margin-bottom: 5px;
}